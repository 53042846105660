.App {
  background: #f4f4f4;
}

.Toolbar {
  justify-content: center;
}

.LogoContainer {
  width: 100px;
}

.LogoContainer svg {
  fill: #006bd6;
}

.WelcomeLoader {
  position: fixed;
  top: 50%;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.PayrollImage {
  width: 60%;
}

.rsw_2f {
  display: none;
}
.rsw_3G {
  display: block;
  min-height: calc(100vh - 56px);
}

.Layout {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 50px;
  min-height: calc(100vh - 56px - 60px - 50px);
  flex: 1;
}

.Layout.Scrolling {
  height: initial;
}

.Layout.Centered {
  justify-content: center;
}

.WelcomeIntro {
  padding: 32px 0;
  background: #6ddfdf;
  text-align: center;
}

.FormProgress {
  position: absolute;
  top: 68px;
  width: 100%;
}

.BottomNavButtons {
  padding-top: 100px;
  /* padding-bottom: 50px; */
}

.ButtonGroup {
  display: flex;
  justify-content: space-between;
}

.Dialog {
  min-width: 400px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.ModalLoader {
  position: absolute;
  width: 100%;
  top: 0;
}

.ModalContent {
  width: 100%;
  min-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiLinearProgress-root {
  height: 10px !important;
}

@media (min-width: 600px) {
  .WelcomeIntro {
    padding: 60px 0;
    background: #6ddfdf;
    text-align: center;
  }
  .FormProgress {
    top: 75px;
  }
  .ButtonGroup {
    justify-content: space-between;
  }
  .Layout {
    min-height: calc(100vh - 64px - 60px - 50px);
  }
  .WelcomeCta {
    width: 50%;
  }
  .WelcomeLoader {
    position: fixed;
    top: 50%;
    max-width: 800px;
    left: 50%;
    transform: translateX(-50%);
  }
  .rsw_3G {
    display: block;
    min-height: calc(100vh - 64px);
  }
  .PayrollImage {
    width: 30%;
  }
}
